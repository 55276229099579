import axios from '@/config';

export async function getPublicKey() {
  try {
    const response = await axios.get(`/get_public_key`);
    const publicKey = response.data.data;
    localStorage.setItem('publicKey', publicKey);
  } catch (error) {
    console.error('Error getting public key:', error);
  }
}