import JSEncrypt from 'jsencrypt'; // 确保已经安装并导入了 JSEncrypt

/**
 * 使用公钥加密密码
 * @param {string} publicKey - 公钥
 * @param {string} password - 需要加密的密码
 * @returns {string} 加密后的密码
 */
export function encryptPassword(publicKey, password) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(password);
}
