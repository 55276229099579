<template>
    <div>
      <div class="container">
        <el-descriptions :title="count_list_name_title" direction="vertical" :column="4" border></el-descriptions>
        <div>
          <el-button type="primary" @click="handleAdd">添加Gotify服务器</el-button>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="host" label="Host">
          <template slot-scope="scope">
            <span v-if="!scope.row.editing">{{ scope.row.host }}</span>
            <el-input v-else v-model="scope.row.tmp_host"></el-input>
          </template>
        </el-table-column>
  
        <el-table-column prop="port" label="Port">
          <template slot-scope="scope">
            <span v-if="!scope.row.editing">{{ scope.row.port }}</span>
            <el-input v-else v-model="scope.row.tmp_port"></el-input>
          </template>
        </el-table-column>
  
        <el-table-column prop="token" label="Token">
          <template slot-scope="scope">
            <!-- <div v-if="!scope.row.editing" class="token-display"> -->
              <!-- <span>{{ scope.row.showToken ? scope.row.token : '••••••••' }}</span>
              <el-button 
                icon="el-icon-view" 
                @click="toggleTokenVisibility(scope.row)"
                class="eye-button"
              ></el-button> -->
            <!-- </div> -->
            <!-- <el-input -->
              <el-tooltip 
      :content="'token不允许编辑，请先删除再添加'"
      placement="top"
    >

  <div class="cell-content">
            <span>{{ scope.row.showToken ? scope.row.token : '••••••••' }}</span>
              <el-button 
                icon="el-icon-view" 
                @click="toggleTokenVisibility(scope.row)"
                class="eye-button"
              ></el-button> 
              <!-- v-model="scope.row.tmp_token"
              :type="scope.row.showToken ? 'text' : 'password'"
              show-password
            > -->
          <!-- </el-input> -->
  </div>
</el-tooltip>
          </template>
        </el-table-column>
  
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.editing" type="primary" size="mini" @click="startEditing(scope.row)">
              编辑
            </el-button>
            <el-button v-else type="success" size="mini" @click="confirmEditing(scope.row)">确认</el-button>
            <el-button v-if="scope.row.editing" type="danger" size="mini" @click="cancelEditing(scope.row)">取消</el-button>
            <el-popconfirm
              title="确定删除这个服务器吗？"
              @confirm="handleDelete(scope.row)"
            >
              <el-button slot="reference" type="danger" size="mini">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 添加服务器对话框 -->
      <el-dialog :visible.sync="addDialogVisible" title="添加Gotify服务器">
        <el-form :model="formData" label-width="100px">
          <el-form-item label="Host">
            <el-input v-model="formData.host"></el-input>
          </el-form-item>
          <el-form-item label="Port">
            <el-input v-model="formData.port"></el-input>
          </el-form-item>
          <el-form-item label="Token">
            <el-input 
              v-model="formData.token" 
              type="password" 
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleConfirmAdd">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        v-if="errorMessage"
        title="编辑结果"
        :visible.sync="dialogVisible">
      <span>{{ errorMessage }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    </div>
  </template>
  
  <script>
  import axios from '@/config';
  // import LunarCalendar from 'lunar-calendar';
  import { getPublicKey } from '@/util/getPublicKey';
  import { encryptPassword } from '@/util/encry_decry_info';

  export default {
    data() {
    return {
      count_list_name_title: 'Gotify服务器',
      tableData: [],
      addDialogVisible: false,
      formData: {
        host: '',
        port: '',
        token: ''
      },
      errorMessage: '',
      dialogVisible: false,
      addTaskDialogVisible: false,
    };
  },
    mounted() {
      this.fetchServers();
      getPublicKey();
    },
    watch: {
    },
  
    methods: {
  
      async fetchServers() {
        try {
          const response = await axios.get('/list_gotify_server');
          const responseData = response.data;
          console.log(responseData, 111)
          if (responseData.code === '0000') {
            this.tableData = responseData.data.map(server => ({
              ...server,
              tmp_host: server.host,
              tmp_port: server.port,
              tmp_token: server.token,
              editing: false,
              showToken: false
            }));
  
          } else {
            this.showErrorDialog(responseData.msg);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          this.showErrorDialog('请求数据时出错');
        }
      },
      toggleTokenVisibility(row) {
      row.showToken = !row.showToken;
    },
      handleAdd() {
        this.addDialogVisible = true;
      },
      async handleConfirmAdd() {
        try {
            const publicKey = localStorage.getItem('publicKey');
          const encrypt_token = encryptPassword(publicKey, this.formData.token)
          const tmp_formdata = { ...this.formData };
          tmp_formdata.token = encrypt_token
          console.log(encrypt_token, 111)
          // this.formData.token = encrypt_token
          console.log(this.formData)
          const response = await axios.post('/add_gotify_server', tmp_formdata);
          const responseData = response.data;
          if (responseData.code === '0000') {
            this.addDialogVisible = false;
            this.fetchServers();
            this.showErrorDialog('添加配置成功');
          } else {
            this.showErrorDialog(responseData.msg);
          }
        } catch (error) {
          console.error('Error adding task:', error);
          this.showErrorDialog('添加配置时出错');
        }
      },
  
      startEditing(row) {
        row.editing = true;
        row.tmp_host = row.host;
        row.tmp_port = row.port;
        row.tmp_token = row.token;
      },
      async confirmEditing(row) {
        try {
          const response = await axios.put(`/update_gotify_server/${row.id}`, {
            host: row.tmp_host,
            port: row.tmp_port,
            token: row.token
          });
          console.log(response, 111)
          const responseData = response.data;
          if (responseData.code === '0000') {
            row.editing = false;
            this.fetchServers();
            this.showErrorDialog('编辑成功');
          } else {
            this.showErrorDialog('后端接口报错' + responseData.msg);
          }
        } catch (error) {
          console.error('Error editing task:', error);
          this.showErrorDialog('编辑配置时出错' + error);
        }
      },
      cancelEditing(row) {
        row.editing = false;
        row.tmp_host = row.host;
        row.tmp_port = row.port;
        row.tmp_token = row.token;
      },
      async handleDelete(row) {
        try {
            console.log(row.id, row)
          const response = await axios.put(`/delete_gotify_server/${row.id}`);
          const responseData = response.data;
          if (responseData.code === '0000') {
            this.fetchServers();
          } else {
            this.showErrorDialog(responseData.msg);
          }
        } catch (error) {
          console.error('Error deleting task:', error);
          this.showErrorDialog('删除配置时出错');
        }
      },
      showErrorDialog(message) {
        this.errorMessage = message;
        this.dialogVisible = true;
      },
      showDeleteConfirm(row) {
        this.$confirm('确认删除此任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleDelete(row);
        }).catch(() => {
          // 取消删除
        });
      }
    }
  };
  </script>
  
  <style>

.cell-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px; /* 根据实际表格样式调整 */
}
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .task_lunar {
    display: flex;
    justify-content: space-between;
  }
  
  .el-table .expired-task {
    background-color: #d3d3d3; /* 灰色 */
    z-index: 1; /* 设置灰色行的 z-index */
  }
  
  .el-table .near-deadline-task {
    background: #ffcccc; /* 浅红色 */
  }
  
  .el-table .soon-deadline-task {
    background: #ffffcc; /* 黄色 */
  }
  
  .el-table .upcoming-deadline-task {
    background: palegreen; /* 绿色 */
  }
  
  .el-table .normal-task {
    /* 默认样式 */
    background: #7FFFD4;
  }
  
  label {
    display: flex;
    width: 300px;
    justify-content: space-between;
    align-items: center;
  }
  
  
  </style>